<template>
  <div id="terminal_chrome_component">
    <div class="terminal_chrome_container">
      <div class="terminal_chrome_container__item title_container">
        <div class="title_container__item title_status_container">
          <div class="title_status_container__item title">
            <label>Chromium debug</label>
          </div>
          <div v-if="showLoad" class="title_status_container__item">
            <div class="spring-spinner">
              <div class="spring-spinner-part top">
                <div class="spring-spinner-rotator"></div>
              </div>
              <div class="spring-spinner-part bottom">
                <div class="spring-spinner-rotator"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="title_container__item create_button">
          <button
            v-if="online && !anyWaitingTask"
            @click="onCreateChrome"
            class="blue_button"
          >
            Создать
          </button>
          <button v-if="!online || anyWaitingTask" class="gray_button">
            Создать
          </button>
        </div>
      </div>
      <div class="terminal_chrome_container__item task_status_container">
        <action-bar
          :show="actionBarData.main.show"
          @close-action-bar="resetActionBar('main')"
          :status="actionBarData.main.status"
          :action="actionBarData.main.action"
          :broadDescription="actionBarData.main.broadDescription"
        />
      </div>
      <div
        v-if="chromes.length === 0"
        class="terminal_chrome_container__item not_found"
      >
        Не найдено
      </div>
      <div class="terminal_chrome_container__item chrome_container">
        <div
          v-for="(el, i) in chromes"
          :key="i"
          class="chromes_container__item chrome_line_container"
          :class="showNewChrome && i === 0 ? 'selected' : ''"
        >
          <div class="chrome_line_container__item chrome_info_container">
            <div class="chrome_info_container__item task_id_container">
              <div class="task_id_container__item"><label>ID:</label></div>
              <div class="task_id_container__item">
                <label class="task_id_label">{{ el.taskId }}</label>
              </div>
            </div>
            <div class="chrome_info_container__item date_container">
              <div class="date_container__item"><label>Date:</label></div>
              <div class="date_container__item">
                <label>{{ el.date }}</label>
              </div>
            </div>
            <div class="chrome_info_container__item port">
              <label>{{ formChromeSSH(el.port) }}</label>
            </div>
            <div
              class="chrome_info_container__item task_status"
              :class="`task_status_${el.taskState.toLowerCase()}`"
            >
              <label>{{ el.taskState }}</label>
            </div>
          </div>

          <div class="chrome_line_container__item buttons_container">
            <div
              class="buttons_container__item"
              :class="el.taskState !== 'OK' ? 'disable_button' : ''"
            >
              <button
                v-if="el.taskState === 'OK'"
                title="Скопировать"
                @click="onCopy(event, formChromeSSH(el.port))"
              >
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16667 17.3327C1.70833 17.3327 1.31583 17.1696 0.989167 16.8435C0.663055 16.5168 0.5 16.1243 0.5 15.666V3.99935H2.16667V15.666H11.3333V17.3327H2.16667ZM5.5 13.9993C5.04167 13.9993 4.64944 13.8363 4.32333 13.5102C3.99667 13.1835 3.83333 12.791 3.83333 12.3327V2.33268C3.83333 1.87435 3.99667 1.48185 4.32333 1.15518C4.64944 0.829071 5.04167 0.666016 5.5 0.666016H13C13.4583 0.666016 13.8508 0.829071 14.1775 1.15518C14.5036 1.48185 14.6667 1.87435 14.6667 2.33268V12.3327C14.6667 12.791 14.5036 13.1835 14.1775 13.5102C13.8508 13.8363 13.4583 13.9993 13 13.9993H5.5ZM5.5 12.3327H13V2.33268H5.5V12.3327Z"
                    fill="#010101"
                  />
                </svg>
              </button>
              <button v-else title="Скопировать">
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16667 17.3327C1.70833 17.3327 1.31583 17.1696 0.989167 16.8435C0.663055 16.5168 0.5 16.1243 0.5 15.666V3.99935H2.16667V15.666H11.3333V17.3327H2.16667ZM5.5 13.9993C5.04167 13.9993 4.64944 13.8363 4.32333 13.5102C3.99667 13.1835 3.83333 12.791 3.83333 12.3327V2.33268C3.83333 1.87435 3.99667 1.48185 4.32333 1.15518C4.64944 0.829071 5.04167 0.666016 5.5 0.666016H13C13.4583 0.666016 13.8508 0.829071 14.1775 1.15518C14.5036 1.48185 14.6667 1.87435 14.6667 2.33268V12.3327C14.6667 12.791 14.5036 13.1835 14.1775 13.5102C13.8508 13.8363 13.4583 13.9993 13 13.9993H5.5ZM5.5 12.3327H13V2.33268H5.5V12.3327Z"
                    fill="#a1a3ab"
                  />
                </svg>
              </button>
            </div>
            <div class="buttons_container__item">
              <button title="Удалить" @click="onDelete(el.taskId)">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.83301 15.5C2.37467 15.5 1.98245 15.3369 1.65634 15.0108C1.32967 14.6842 1.16634 14.2917 1.16634 13.8333V3H0.333008V1.33333H4.49967V0.5H9.49967V1.33333H13.6663V3H12.833V13.8333C12.833 14.2917 12.67 14.6842 12.3438 15.0108C12.0172 15.3369 11.6247 15.5 11.1663 15.5H2.83301ZM11.1663 3H2.83301V13.8333H11.1663V3ZM4.49967 12.1667H6.16634V4.66667H4.49967V12.1667ZM7.83301 12.1667H9.49967V4.66667H7.83301V12.1667Z"
                    fill="#010101"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sshUser, sshHost } from "../config.js";
import { defineAsyncComponent } from "vue";
export default {
  components: {
    "action-bar": defineAsyncComponent(() =>
      import("@/components/ActionBar.vue")
    ),
  },
  props: {
    terminalId: {
      type: Number,
    },
    online: {
      type: Boolean,
    },
  },
  data() {
    return {
      chromes: [],
      showLoad: false,
      showNewChrome: false,
      actionBarData: {
        main: {
          status: "",
          action: "",
          broadDescription: "",
          show: false,
        },
      },
    };
  },
  methods: {
    resetActionBar(type) {
      this.actionBarData[type] = {
        status: "",
        action: "",
        broadDescription: "",
        show: false,
      };
    },
    formChromeSSH(port) {
      return port
        ? `ssh -L 0.0.0.0:9223:localhost:3999  ${sshUser}@${sshHost} -N -p ${port}`
        : "Неизвестно";
    },
    // copy data to buffer
    onCopy(event, text) {
      navigator.clipboard.writeText(text);
    },

    closeTaskStatus(event, taskId) {
      delete this.tasks[taskId];
    },

    // delete chrome
    onDelete(taskId) {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Удаление ssh",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/task?taskId=${taskId}`,
          method: "DELETE",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (delete data)", res);
          if (res.status === 200) {
            this.chromes = this.chromes.filter(
              (e) => e.taskId !== Number(taskId)
            );
            this.actionBarData.main.status = "OK";
            setTimeout(() => {
              this.resetActionBar("main");
            }, 4000);
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = res.data.payload;
        })
        .catch((error) => {
          console.log("Error to delete ssh", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Удаление ssh"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },

    // create new chrome
    onCreateChrome() {
      this.actionBarData.main = {
        status: "WAITING",
        action: "Запрос chrome port",
        show: true,
      };
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/chromeport?terminalId=${this.terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          console.log(
            "Response from proxy api obtained (create chromes data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.chromes.unshift({
                  date: taskResponse.data.payload.createDttm,
                  port: taskResponse.data.payload.taskData.result,
                  taskId: taskResponse.data.payload.taskId,
                  taskState: taskResponse.data.payload.taskState,
                });
                this.showNewChrome = true;
                this.actionBarData.main.status = "OK";
                setTimeout(() => {
                  this.resetActionBar("main");
                }, 4000);
                setTimeout(() => {
                  this.showNewChrome = false
                }, 4000);
                return
              }
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = taskResponse.data.payload;

            })
            .catch((error) => {
              this.actionBarData.main.status = "ERROR";
              this.actionBarData.main.broadDescription = error.data;
            });
        })
        .catch((error) => {
          console.log("Error to get screen", error);
          if (error.request.status === 403) {
            this.actionBarData.main.status = "ERROR";
            (this.actionBarData.main.action = "Запрос chrome port"),
              (this.actionBarData.main.broadDescription = "Недостаточно прав");
            return;
          }
          this.actionBarData.main.status = "ERROR";
          this.actionBarData.main.broadDescription = error.data;
        });
    },

    // gets all records
    getChromes() {
      this.showLoad = true;
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/tasks?terminalId=${this.terminalId}&taskType=chromeport`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy api obtained (chromes data)", res);
          const data = [];
          res.data.payload.tasks.forEach((el) => {
            data.push({
              date: el.createDttm,
              port: el.taskData.result,
              taskId: el.taskId,
              taskState: el.taskState,
            });
          });
          this.chromes = data.sort((a, b) => {return Date.parse(b.date, "yyyy-MM-dd HH:mm:ss") - Date.parse(a.date, "yyyy-MM-dd HH:mm:ss")})
        })
        .catch((error) => {
          console.log("Error to get chromes data data", error);
        })
        .finally(() => {
          this.showLoad = false;
        });
    },
  },
  computed: {
    anyWaitingTask() {
      return this.actionBarData.main.status === "WAITING"
    },
  },

  mounted() {
    this.getChromes();
  },
};
</script>

<style lang="less">
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";

.terminal_chrome_container {
  display: flex;
  flex-direction: column;
  padding: 25px 25px;

  .not_found {
    display: flex;
    justify-content: center;
  }

  .buttons_container {
    display: flex;
    justify-content: space-between;
    button {
      all: unset;
      padding: 6px 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
  }
}

.title_container {
  display: flex;
  justify-content: space-between;
}

.chrome_container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  overflow-y: scroll;
  max-height: 70vh;
  padding-right: 10px;
}

.chrome_line_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #c9c9c9;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  padding: 5px;
  margin-bottom: 15.5px;

  &.selected {
    background: #f1f6ff;
    border-radius: 5px;
    padding: 5px;
    border-bottom: unset
  }
}

.title_status_container {
  display: flex;
  align-items: center;

  .title {
    margin-right: 15px;
  }
}

.task_status_container {
  display: flex;
  flex-direction: column;
  margin-top: 2px;
  height: 40px;
}


.chrome_info_container {
  display: flex;
  align-items: center;
  .chrome_info_container__item {
    margin-right: 20px;
  }

  .task_status {
    padding: 3px 16px;
    height: 10px;
    border-radius: 20px;
    font-size: 10px;
    display: flex;
    align-items: center;
    color: #ffffff;

    &.task_status_pending {
      background-color: #4150b7;
    }
    &.task_status_waiting {
      background-color: #f0ad4e;
    }
    &.task_status_ok {
      background-color: #5bc367;
    }
    &.task_status_error {
      background-color: #f44c4b;
    }
    &.task_status_inprocess {
      background-color: #4150b7;
    }
  }

  .task_id_container {
    display: flex;
    flex-direction: column;
    // justify-items: baseline;
    width: 10%;
    .task_id_label {
      max-width: 98%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .date_container {
    display: flex;
    flex-direction: column;
    // justify-items: baseline;
    width: 25%;
  }

  .port {
    width: 40%;
  }
}
</style>
